<template lang="pug">
    v-dialog(ref='dialog' v-model='modal' persistent width='320px' overlay-opacity='0.9')
        template(v-slot:activator='{ on }')
            v-text-field(v-model='date' prepend-icon='event' readonly v-on='on')
        v-card.pa-3
            v-date-picker.elevation-0(v-model='date' :min="minDate" @input="saveDate" :allowed-dates='allowedDates')
</template>

<script>
import { mapActions } from 'vuex';
export default {
	data() {
		return {
			date: this.$moment().format('YYYY-MM-DD'),
			modal: false
		};
	},
	mounted() {
		this.setOpenAutoSearch(false);
		this.$emit('emitupdateTodayDate', this.date);
	},
	computed: {
		minDate() {
			return this.$moment()
				.subtract(24, 'months')
				.format('YYYY-MM-01');
		}
	},
	methods: {
		...mapActions(['setOpenAutoSearch']),
		saveDate(date) {
			this.modal = false;
			this.$refs.dialog.save(date);
			this.$emit('emitupdateTodayDate', this.date);
		},
		allowedDates(val) {
			return val <= this.$moment().format('YYYY-MM-DD');
		}
	}
};
</script>
