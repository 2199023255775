import light from '@/theme/light';
// import Material from 'vuetify/es5/util/colors';

export function bar() {
	const option = {
		color: light.color,
		title: {
			text: '',
			subtext: '',
			textStyle: {
				color: '#FFF',
			},
		},
		grid: {
			left: '14%'
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
			},
		},
		tooltip: {
			axisPointer: {
				type: 'shadow',
			},
			textStyle: {
				color: 'black',
			},
			confine: true,
			formatter: '',
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			name: '',
			type: 'category',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
			},
			data: [],
		},
		yAxis: [{
			name: '',
			type: 'value',
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: 'white' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				show: true,
				color: '#FFF',
			},
		}],
		series: {
			name: '',
			type: 'bar',
			label: {},
			data: [],
		},
	};
	return option;
}

export function line(){
	const option = {
		color: light.color,
		title: {
			text: '',
			x: 'left',
			textStyle: {
				color: '#FFF',
			},
		},
		toolbox: {
			show: false,
			itemSize: 30,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
						stack: 'Stack'
					},
					type: ['line', 'bar', 'stack']
				},
			},
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'line',
			},
			backgroundColor: '#FFF',
			confine: true,
			textStyle: { color: 'black' },
			formatter: '',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			type: 'category',
			data: [],
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				// interval: 0,
				color: '#FFF',
			},
		},
		yAxis: {
			type: 'value',
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				color: '#FFF',
			},
			splitLine: { lineStyle: { color: '#37474F' } },
		},
		series: []
		// series: {
		// 	type: 'line',
		// 	label: {
		// 		normal: {
		// 			show: false,
		// 			position: 'top',
		// 		},
		// 	},
		// 	name: '',
		// 	data: [],
		// },
	};
	return option;
}
export function pie() {
	const option = {
		color: light.color,
		title: {
			text: '',
			// x: 'center',
			textStyle: {
				color: '#FFF',
			},
		},
		grid: { left: '3%' },
		tooltip: {
			confine: true,
			trigger: 'item',
			formatter: '',
			extraCssText: 'z-index:8',
		},
		legend: {
			
		},
		series: [
			{
				name: '',
				type: 'pie',
				// radius: ['50%', '60%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
				},
				labelLine: {
					show: false,
				},
				data: [],
			},
		],
	};
	return option;
}
