<template lang="pug">
    v-dialog(ref="dialog" v-model="modal" :return-value.sync="data" persistent width="320px" overlay-opacity='0.9')
        template(v-slot:activator='{ on }')
            v-text-field.dataInput(v-model="data" outlined :value='quickItems.label' append-icon="keyboard_arrow_down" :label='label' readonly v-on='on')
        v-card.pa-3
            v-radio-group(v-model='quickPick' v-if='quickPick !== "0"')
                v-radio(v-for='(item, index) in quickItems' :key='index' :label='`${item.label}`' :value='item.value' :disabled='item.disable' :ripple='false')
            v-row
                v-col.d-flex.justify-space-between
                    v-btn.date-btn.mr-3(color="grey darken-2" @click='cancelData' large) {{$t('common.cancel')}}
</template>
<script>
export default {
	props: ['dataStatus'],
	data() {
		return  {
			modal: false,
			// label: this.$t('common.column'),
			label: '',
			data: this.$t('charts.turnover'),
			language: 'en',
			quickPick: '1',
			quickItems: [
				{
					label: this.$t('charts.turnover'),
					value: '1',
					disable: false
				},
				{
					label: this.$t('charts.netWin'),
					value: '2',
					disable: false
				},			
			],
		};
        
	},
	mounted(){
		this.language = localStorage.getItem('language');
	},
	computed: {
	},
	watch: {
		dataStatus:function(val){
			if(val === this.$t('charts.turnover')){
				this.quickPick = '1';
			} else if(val === this.$t('charts.netWin')){
				this.quickPick = '2';
			} 
		},
		quickPick: function(val){
			if(val === '1'){
				this.data = this.$t('charts.turnover');
				this.saveData(this.data);
			} else if(val === '2'){
				this.data = this.$t('charts.netWin');
				this.saveData(this.data);
			} 
		},
	},
	methods: {
		cancelData(){
			this.modal = false;
		},
		saveData(data){
			this.$refs.dialog.save(data);
			this.data = data;
			this.$emit('emitupdateData',data);
		},
	}
};
</script>
