import _ from 'lodash';
import moment from 'moment';
import repla from '@/util/dataFilter';
import fetch from '@/util/fetch';
import i18n from '@/locale';
import iter_all from '@/util/format';
import statusCode from '@/assets/constant/statusCode';

// 取得 today
export async function getToday(reqData) {
	const result = await fetch({
		url: '/cars/provider/today',
		method: 'post',
		data: {
			reqData,
		},
	});
	if(result.status != statusCode.STATUS_OK)
		return result;

	const tmp = result.result.res;
	const res = await repla(tmp);
	iter_all(res);
	return result;
}
// 取得 daily
export async function getDaily(reqData) {
	const result = await fetch({
		url: '/cars/provider/daily',
		method: 'post',
		data: {
			reqData,
		},
	});
	if(result.status != statusCode.STATUS_OK)
		return result;

	let tmp = result.result.res;
	iter_all(tmp);

	tmp.ctx_cvt_org = [];
	tmp.ctx_cvt_all = [];
	for (const i of tmp.ctx_cvt) {
		if(i.dim_ptt) {
			tmp.ctx_cvt_org.push(i);
		} else {
			i.dim_ptt = null;
			tmp.ctx_cvt_all = i;
		}
	}
	return result;
}
// 取得 monthly
export async function getMonthly(reqData) {
	const result = await fetch({
		url: '/cars/provider/monthly',
		method: 'post',
		data: {
			reqData,
		},
	});
	if(result.status != statusCode.STATUS_OK)
		return result;

	let tmp = result.result.res;
	iter_all(tmp);

	tmp.ctx_cvt_org = [];
	tmp.ctx_cvt_all = [];
	for (const i of tmp.ctx_cvt) {
		if(i.dim_ptt) {
			tmp.ctx_cvt_org.push(i);
		} else {
			i.dim_ptt = null;
			tmp.ctx_cvt_all = i;
		}
	}
	return result;
}
// 取得 market daily
export async function getMarketDaily(reqData) {
	const result = await fetch({
		url: '/cars/provider/market/daily',
		method: 'post',
		data: {
			reqData,
		},
	});
	if(result.status != statusCode.STATUS_OK)
		return result;

	let tmp = result.result.res;
	iter_all(tmp);

	return result;
}
// 取得 market monthly
export async function getMarketMonthly(reqData) {
	const result = await fetch({
		url: '/cars/provider/market/monthly',
		method: 'post',
		data: {
			reqData,
		},
	});
	if(result.status != statusCode.STATUS_OK)
		return result;

	let tmp = result.result.res;
	iter_all(tmp);

	return result;
}

