import moment from 'moment';
import { nFormatter } from '@/util/format';
import { roundOffFilter } from '@/util/format';

export function revenueChart(data) {
	const option = {
		color: data.color || ['#388E3C', '#F9A825'],
		title: {
			text: data.title || '',
			subtext: data.subtext || '',
			textStyle: {
				color: '#FFF',
			},
		},
		legend: {
			top: 'bottom',
			textStyle: { color: '#FFF' },
			data: [data.bar.name, data.line.name] || []
		},
		grid: {
			left: '16%',
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
				restore: {
					show: true,
					title: 'restore'
				},
			},
		},
		tooltip: {
			axisPointer: {
				type: 'shadow',
			},
			confine: true,
			extraCssText: 'z-index:8',
			formatter: params => {
				// let bas = `${data.date.name}: ${moment(params[0].name).format(data.date.format)}<br/>`;
				// if (params[0].value < 0) {
				// 	bas += `${params[0].marker}${params[0].seriesName}: -${nFormatter(Math.abs(params[0].value), 1)}<br/>`;
				// } else {
				// 	bas += `${params[0].marker}${params[0].seriesName}: ${nFormatter(params[0].value, 1)}<br/>`;
				// }
				// if (params[1].value < 0) {
				// 	bas += `${params[1].marker}${params[1].seriesName}: -${nFormatter(Math.abs(params[1].value), 1)}<br/>`;
				// } else {
				// 	bas += `${params[1].marker}${params[1].seriesName}: ${nFormatter(params[1].value, 1)}<br/>`;
				// }
				// return bas;
				let str = `${data.date.name}: ${moment(params[0].name).format(data.date.format)}<br/>`;
				for (let i of params) {
					str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
				}
				return str;
			},
			textStyle: { color: 'black' },
			trigger: 'axis',
			triggerOn: 'mousemove',
		},
		xAxis: [{
			name: '',
			type: 'category',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					return moment(value).format(data.date.format);
				}
			},
			data: data.xAxis.data || [],
		}],
		yAxis: [{
			name: '',
			type: 'value',
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: 'white' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					let yAxisData;
					if (value >= 0) {
						yAxisData = `${nFormatter(value, 1)}`;
					} else {
						yAxisData = `-${nFormatter(Math.abs(value, 1))}`;
					}
					return yAxisData;
				},
			},
		}],
		series: [{
			name: data.bar.name || '',
			type: 'bar',
			label: {},
			// markPoint: {
			// 	label: {
			// 		formatter: parameter => {
			// 			let value;
			// 			if(parameter.value >= 0){
			// 				value = nFormatter(parameter.value, 1);
			// 			} else {
			// 				value = `-${nFormatter(Math.abs(parameter.value), 1)}`;
			// 			}
			// 			return value;
			// 		}
			// 	},
			// 	symbolSize: [1, 1],
			// 	data: [
			// 		{ type: 'max', name: 'max', label: { position: 'top', color: '#FFF' } },
			// 		{ type: 'min', name: 'min', label: { position: 'top', color: '#FFF' } }
			// 	]
			// },
			data: data.bar.data || [],
		}, {
			name: data.line.name || '',
			type: 'line',
			symbol: 'circle',
			yAxisIndex: 0,
			label: {},
			data: data.line.data || [],
		}],
	};
	return option;
}
